enum State {
  main = 0,
  confirmation = 1,
  report = 2,
  register = 3,
  loading = 4,
  error = 5,
  admin = 6,
  employees = 7,
  comment = 8,
}

export default State;
