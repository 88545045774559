import { createSlice } from '@reduxjs/toolkit';
import { OrderTask } from '../../@types/orderTask';

// ----------------------------------------------------------------------

const initialState: { currentOrderTask: OrderTask | undefined, orderTasks: OrderTask[] } = {
  currentOrderTask: undefined,
  orderTasks: [],
};

const slice = createSlice({
  name: 'orderTask',
  initialState,
  reducers: {
    setOrderTask(state, action) {
        state.currentOrderTask = action.payload
    },

    setOrderTasks(state, action) {
      state.orderTasks = action.payload
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setOrderTask, setOrderTasks } = slice.actions;
