// material
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ width: 200, height: 40, ...sx }}>
      { /** <img src={logo} style={{ height: 40 }} alt='logo' /> **/ }
    </Box>
  );
}
