import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import globalStateReducer from "./slices/globalState";
import usersReducer from "./slices/user";
import machineReducer from "./slices/machine";
import orderTaskReducer from "./slices/orderTask";
import shiftReducer from "./slices/shift";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  user: usersReducer,
  machine: machineReducer,
  shift: shiftReducer,
  orderTask: orderTaskReducer,
  globalState: globalStateReducer,
});

export { rootPersistConfig, rootReducer };
