import { createSlice } from "@reduxjs/toolkit";
import { Shift } from "../../@types/shift";

// ----------------------------------------------------------------------

const initialState: {
  currentShift: Shift | undefined;
  shifts: Shift[];
} = {
  currentShift: undefined,
  shifts: [],
};

const slice = createSlice({
  name: "shift",
  initialState,
  reducers: {
    setShift(state, action) {
      state.currentShift = action.payload;
    },

    setShifts(state, action) {
      state.shifts = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setShift, setShifts } = slice.actions;
