// routes
import Router from "./routes";
// components
import { ProgressBarStyle } from "./components/LoadingScreen";
import NotistackProvider from "./components/NotistackProvider";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// components
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import CacheBuster from "react-cache-buster";
import p from "../package.json";

// ----------------------------------------------------------------------

export default function App() {
  const isProduction = process.env.NODE_ENV === "production";
  return (
    <CacheBuster
      currentVersion={p.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <ThemeConfig>
        <ThemePrimaryColor>
          <NotistackProvider>
            <GlobalStyles />
            <ProgressBarStyle />
            <Router />
          </NotistackProvider>
        </ThemePrimaryColor>
      </ThemeConfig>
    </CacheBuster>
  );
}
