import { createSlice } from '@reduxjs/toolkit';
import { Machine } from '../../@types/machine';

// ----------------------------------------------------------------------

const initialState: { currentMachine: Machine | undefined, machines: Machine[] } = {
  currentMachine: undefined,
  machines: [],
};

const slice = createSlice({
  name: 'machine',
  initialState,
  reducers: {

    setMachine(state, action) {
      state.currentMachine = action.payload
    },

    setMachines(state, action) {
      state.machines = action.payload
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setMachine, setMachines } = slice.actions;
